<template>
  <div class="widget-inner">
    <div class="current">
      {{$formatNumber(data.current)}}
    </div>
    <div class="previous" v-if="'previous' in data && (data.current - data.previous) != 0">
      <i :class="['fas', (data.current - data.previous) < 0 ? 'fa-arrow-down' : ((data.current - data.previous) > 0 ? 'fa-arrow-up' : 'fa-arrow-right')]"></i> {{$formatNumber(Math.abs((data.current - data.previous)))}} from previous period
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
}
</script>

<style lang="scss" scoped>
.current {
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;

  + .previous {
    text-align: center;
    font-size: 14px;
    color: $muted-text;
  }
}
</style>